<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div class="container">
    <div class="system-pages-title d-flex ">
      <div class="me-2 trusted-icon">
        <TrustedAgenciesIcon/>
      </div>
      {{ $t('trusted-agencies') }}
    </div>
    <div class="system-pages-content" v-if="$root.$i18n.locale === 'bg'">
      <p><strong>Търсете синия маркер</strong> - в нашата платформа може да намерите само реални обяви от предварително
        одобрени и надеждни агенции за недвижими имоти.</p>
      <p><br></p>
      <p>Разчитаме на коректността и сме ограничили свободното регистриране на агенции за имоти - <strong>единствено физическите
        лица могат да се регистрират свободно</strong>. Това означава, че в нашата платформа може да намерите само два вида обяви
        - от физически лица (без маркер) или коректни агенции (със син маркер). Имаме механизми за предотвратяването и
        минимизирането на фалшиви обяви, което приемаме като наша мисия.</p>
      <p><br></p>
      <p>На първо място държим на качеството - всяка агенция за недвижими имоти и всеки партньор на Съквартирантите е
        предварително проверен и задължен да спазва добрите практики с дългосрочен договор за партньорство. При системни
        нарушения, ние снемаме доверието си и прекратяваме работа с дадения брокер или партньор.</p>
    </div>
    <div class="system-pages-content" v-else>
      <p><strong>Look out for the blue marker</strong> - on our platform, you can find only real listings from pre-approved and reliable real estate agencies.</p>
      <p><br></p>
      <p>We rely on the correctness of all of our partners and have limited the free registration of real estate agencies - <strong>only individuals can register freely</strong>. This means that you can find only two types of ads on our platform - from individuals (without a tag) or legitimate agencies (with a blue tag). We have mechanisms in place to prevent and minimize fake ads, which we take as our mission.</p>
      <p><br></p>
      <p>First of all, we care about quality - every real estate agency and every partner of TheRoommates is pre-screened and obliged to observe good practices with a long-term partnership agreement. In case of systemic violations, we withdraw our trust and terminate the access of the broker or partner.</p>
    </div>
  </div>


</template>
